import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useGlobalFilter, useSortBy, useAsyncDebounce } from "react-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataTableHeadCell from "customComponents/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "customComponents/Tables/DataTable/DataTableBodyCell";

function DataTable({ entriesPerPage, canSearch, showTotalEntries, table, pagination, isSorted, noEndBorder }) {
	const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
	const entries = entriesPerPage.entries
		? entriesPerPage.entries.map((el) => el.toString())
		: ["5", "10", "15", "20", "25", "50", "100"];

	const columns = useMemo(() => table.columns, [table]);
	const data = useMemo(() => table.rows, [table]);

	const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, useGlobalFilter, useSortBy);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows,
		setGlobalFilter,
		state: { globalFilter },
	} = tableInstance;

	// Handle manual pagination props
	const { totalPages, currentPage: externalCurrentPage, onPageChange } = pagination;

	// State to handle page size manually
	const [pageSize, setPageSize] = useState(defaultValue);

	// State to handle the current page, using 1-based index
	const [currentPage, setCurrentPage] = useState(externalCurrentPage || 1);

	// Whenever external current page changes, update the internal state
	useEffect(() => {
		setCurrentPage(externalCurrentPage || 1);
	}, [externalCurrentPage]);

	// Search input value state
	const [search, setSearch] = useState(globalFilter);

	// Search input state handle
	const onSearchChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 100);

	// A function that sets the sorted value for the table
	const setSortedValue = (column) => {
		let sortedValue;

		if (isSorted && column.isSorted) {
			sortedValue = column.isSortedDesc ? "desc" : "asce";
		} else if (isSorted) {
			sortedValue = "none";
		} else {
			sortedValue = false;
		}

		return sortedValue;
	};

	// Set the range of entries being displayed (current page, page size, etc.)
	const entriesStart = (currentPage - 1) * pageSize + 1;
	const entriesEnd = Math.min(currentPage * pageSize, rows.length);

	return (
		<TableContainer sx={{ boxShadow: "none" }}>
			{(entriesPerPage && entriesPerPage.display === true) || canSearch ? (
				<MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
					{entriesPerPage && entriesPerPage.display === true && (
						<MDBox display="flex" alignItems="center">
							<Autocomplete
								disableClearable
								value={pageSize.toString()}
								options={entries}
								onChange={(event, newValue) => {
									setPageSize(parseInt(newValue, 10));
									onPageChange(1); // Reset to first page on page size change
								}}
								size="small"
								sx={{ width: "5rem" }}
								renderInput={(params) => <MDInput {...params} />}
							/>
							<MDTypography variant="caption" color="secondary">
								&nbsp;&nbsp;entries per page
							</MDTypography>
						</MDBox>
					)}
					{canSearch && (
						<MDBox width="12rem" ml="auto">
							<MDInput
								placeholder="Search..."
								value={search}
								size="small"
								fullWidth
								onChange={({ currentTarget }) => {
									setSearch(currentTarget.value);
									onSearchChange(currentTarget.value);
								}}
							/>
						</MDBox>
					)}
				</MDBox>
			) : (
				""
			)}
			<Table {...getTableProps()}>
				<MDBox component="thead">
					{headerGroups.map((headerGroup) => (
						<TableRow {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<DataTableHeadCell
									{...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
									width={column.width ? column.width : "auto"}
									align={column.align ? column.align : "left"}
									sorted={setSortedValue(column)}>
									{column.render("Header")}
								</DataTableHeadCell>
							))}
						</TableRow>
					))}
				</MDBox>
				{rows?.length > 0 ? (
					<TableBody {...getTableBodyProps()}>
						{rows.map((row, key) => {
							prepareRow(row);
							return (
								<TableRow {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<DataTableBodyCell
											noBorder={noEndBorder && rows.length - 1 === key}
											align={cell.column.align ? cell.column.align : "left"}
											{...cell.getCellProps()}>
											{cell.render("Cell")}
										</DataTableBodyCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				) : (
					<MDBox
						position="absolute"
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="100%"
						padding="8px 0">
						<MDTypography fontWeight="light">No data available</MDTypography>
					</MDBox>
				)}
			</Table>

			<MDBox
				display="flex"
				flexDirection={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				width="100%"
				sx={{ overflowX: "scroll" }}
				alignItems={{ xs: "flex-start", sm: "center" }}
				p={!showTotalEntries && totalPages === 1 ? 0 : 3}>
				{showTotalEntries && (
					<MDBox mb={{ xs: 3, sm: 0 }}>
						<MDTypography variant="button" color="secondary" fontWeight="regular">
							Showing {entriesStart} to {entriesEnd} of {rows.length} entries
						</MDTypography>
					</MDBox>
				)}
				{totalPages > 1 && (
					<MDPagination
						width="100%"
						overflowX="scroll"
						variant={pagination.variant ? pagination.variant : "gradient"}
						color={pagination.color ? pagination.color : "info"}>
						{currentPage > 1 && (
							<MDPagination
								item
								onClick={() => onPageChange(currentPage - 1)}
								children={<Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>}></MDPagination>
						)}
						{[...Array(totalPages)].map((_, index) => (
							<MDPagination
								item
								key={index}
								onClick={() => onPageChange(index + 1)} // Page number starts from 1
								active={currentPage === index + 1}
								children={index + 1}></MDPagination>
						))}
						{currentPage < totalPages && (
							<MDPagination
								item
								onClick={() => onPageChange(currentPage + 1)}
								children={<Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>}></MDPagination>
						)}
					</MDPagination>
				)}
			</MDBox>
		</TableContainer>
	);
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
	entriesPerPage: { defaultValue: 100, entries: [5, 10, 15, 20, 25], display: true },
	canSearch: false,
	showTotalEntries: true,
	pagination: { variant: "gradient", color: "info", totalPages: 1, currentPage: 1 }, // Default currentPage is now 1
	isSorted: true,
	noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
	entriesPerPage: PropTypes.oneOfType([
		PropTypes.shape({
			defaultValue: PropTypes.number,
			entries: PropTypes.arrayOf(PropTypes.number),
			display: PropTypes.bool,
		}),
		PropTypes.bool,
	]),
	canSearch: PropTypes.bool,
	showTotalEntries: PropTypes.bool,
	table: PropTypes.objectOf(PropTypes.array).isRequired,
	pagination: PropTypes.shape({
		variant: PropTypes.oneOf(["contained", "gradient"]),
		color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "light"]),
		totalPages: PropTypes.number.isRequired,
		currentPage: PropTypes.number.isRequired,
		onPageChange: PropTypes.func.isRequired,
	}),
	isSorted: PropTypes.bool,
	noEndBorder: PropTypes.bool,
};

export default DataTable;
