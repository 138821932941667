import api from "utils/axios";
import { removeAuthToken } from "utils/authToken";

export default async function signIn(data) {
	const response = await api.post(`/users/login`, data);
	return response.data;
}

export const signOut = () => {
	removeAuthToken();
};
