import React, { useContext, useState } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { Icon } from "@mui/material";
import { registerDevice } from "service/service";

const DeviceForm = (props) => {
	const showNotification = useContext(SnackbarContext);
	const [name, setName] = useState("");
	const [isFormChanged, setIsFormChanged] = useState(false);

	const validateName = (event) => {
		setName(event.target.value);
		setIsFormChanged(true);

		props.sendData(isFormChanged);
	};

	const handleRegisterDevice = async () => {
		const device = {
			name: name,
			mode: "Auto",
			token: null,
		};

		let result = await registerDevice(device);

		if (result) {
			const updatedDevices = props.devices;
			if (!props.device) {
				updatedDevices.push(result);
			}
			props.setDevices(updatedDevices);
			props.setOpen(false);
		}
	};

	return (
		<>
			<MDTypography px={3} variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				{"Register Device"}
			</MDTypography>
			<MDBox py={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "start",
							alignItems: "center",
							width: "100%",
							gap: "8px",
							flexDirection: "row",
						}}>
						<MDBox
							sx={{
								display: "flex",
								justifyContent: "start",
								alignItems: "center",
								width: "100%",
								flexDirection: "column",
							}}>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Name (optional)"
									value={name}
									onChange={(event) => validateName(event)}
									fullWidth
								/>
							</MDBox>
						</MDBox>
					</MDBox>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton
							type="button"
							variant="contained"
							size="medium"
							color="primary"
							onClick={() => {
								handleRegisterDevice();
							}}>
							<Icon fontSize="medium" sx={{ marginRight: "0.5rem" }}>
								create
							</Icon>
							REGISTER
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
};

export default DeviceForm;
