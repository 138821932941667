import { useState, useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "customComponents/Sidenav";
import Configurator from "customComponents/Configurator";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import routes from "routes";

import { useMaterialUIController, setMiniSidenav } from "context";
import { SnackbarProvider } from "components/MDNotification";

const ProtectedRoute = ({ isAuthenticated, element }) => {
	return isAuthenticated ? element : <Navigate to="/connect" />;
};

export default function App() {
	const isAuthenticated = !!localStorage.getItem("token");
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (router, isAuthenticated) =>
		router.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse, isAuthenticated);
			}

			if (route.route) {
				// Use ProtectedRoute for routes other than "/connect"
				if (route.route !== "/connect") {
					return (
						<Route
							exact
							path={route.route}
							element={<ProtectedRoute isAuthenticated={isAuthenticated} element={route.component} />}
							key={route.key}
						/>
					);
				}
				// Allow access to "/connect" route without protection
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<SnackbarProvider>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							color={sidenavColor}
							brandName="Eleon Energy"
							routes={routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
						<Configurator />
					</>
				)}
				<Routes>
					{getRoutes(routes, isAuthenticated)}
					{/* Catch-all route: redirect based on authentication */}
					<Route
						path="*"
						element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/connect" />}
					/>
				</Routes>
			</SnackbarProvider>
		</ThemeProvider>
	);
}
