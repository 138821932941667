import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DataTable from "customComponents/Tables/DataTable";

import settingsDataTable from "layouts/settings/data/settingsDataTable";

function Settings() {
	const [settings, setSettings] = React.useState([]);

	const { columns, rows } = settingsDataTable(settings);

	useEffect(() => {}, []);

	return (
		<DashboardLayout>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox sx={{ width: "100%" }} pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default Settings;
