import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";
import ReportsBarChart from "customComponents/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "customComponents/Charts/LineCharts/ReportsLineChart";
import { getOverview, getDevices } from "service/service"; // Import getDevices API
import { format } from "date-fns";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "components/MDButton";
import { Restore } from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import { fontSize } from "@mui/system";

function Dashboard() {
	const [overviewData, setOverviewData] = useState(null);
	const [devices, setDevices] = useState([]); // Store list of devices
	const [selectedDevice, setSelectedDevice] = useState(null); // Track selected device
	const defaultStartDate = format(
		new Date(new Date().setFullYear(new Date().getFullYear() - 4, 0, 1)).setHours(0, 0, 0, 0),
		"yyyy-MM-dd"
	);
	const defaultEndDate = format(new Date(), "yyyy-MM-dd");
	const [startDate, setStartDate] = useState(defaultStartDate);
	const [endDate, setEndDate] = useState(defaultEndDate);
	const [chartType, setChartType] = useState("bar");
	const [dateType, setDateType] = useState("daily");
	const [anchorEl, setAnchorEl] = useState(null);
	const [deviceMenuAnchorEl, setDeviceMenuAnchorEl] = useState(null); // For device dropdown

	// Function to open the date type dropdown menu
	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// Function to close the date type dropdown menu
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	// Function to handle selecting date type from dropdown
	const handleMenuSelect = (value) => {
		setDateType(value);
		handleMenuClose();
	};

	// Function to fetch devices from the API
	const fetchDevices = async () => {
		const response = await getDevices();
		setDevices(response.data);
	};

	// Function to fetch overview data based on filters
	const fetchOverviewData = async (startDate, endDate, deviceId = "", filterType) => {
		const data = await getOverview(
			filterType,
			format(new Date(startDate), "dd-MM-yyyy"),
			format(new Date(endDate), "dd-MM-yyyy"),
			deviceId
		);
		setOverviewData(data);
	};

	// Function to open the device dropdown menu
	const handleDeviceMenuClick = (event) => {
		setDeviceMenuAnchorEl(event.currentTarget);
	};

	// Function to close the device dropdown menu
	const handleDeviceMenuClose = () => {
		setDeviceMenuAnchorEl(null);
	};

	// Function to handle selecting a device
	const handleDeviceSelect = (deviceId) => {
		setSelectedDevice(deviceId);
		fetchOverviewData(startDate, endDate, deviceId, dateType);
		handleDeviceMenuClose();
	};

	useEffect(() => {
		fetchDevices(); // Fetch devices when component loads
	}, []);

	useEffect(() => {
		fetchOverviewData(startDate, endDate, selectedDevice, dateType);
	}, [dateType, startDate, endDate, selectedDevice]);

	const handleChartTypeChange = (event, newChartType) => {
		if (newChartType !== null) {
			setChartType(newChartType);
		}
	};

	// Function to reset date range to default values
	const resetFilters = () => {
		setStartDate(defaultStartDate);
		setEndDate(defaultEndDate);
		setSelectedDevice(null);
		setChartType("bar");
		fetchOverviewData(defaultStartDate, defaultEndDate, "", "daily");
	};

	const renderSensorChart = (sensor) => {
		if (chartType === "line") {
			return (
				<ReportsLineChart
					color="success"
					title={sensor.title}
					description={sensor.description}
					date={sensor.updatedAt}
					chart={sensor.data}
				/>
			);
		} else if (chartType === "bar") {
			return (
				<ReportsBarChart
					color="info"
					title={sensor.title}
					description={sensor.description}
					date={sensor.updatedAt}
					chart={sensor.data}
				/>
			);
		}
	};

	if (!overviewData) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<Grid container spacing={3} alignItems="center" justifyContent="space-between">
						<Grid item alignItems="center" justifyContent="center">
							{/* Device Filter */}
							<>
								<MDButton
									aria-controls="device-menu"
									aria-haspopup="true"
									color="primary"
									onClick={handleDeviceMenuClick}
									sx={{ marginRight: "1rem" }}
									variant="contained">
									{selectedDevice ? `Device: ${selectedDevice}` : "Select Device"}
								</MDButton>

								<Menu
									id="device-menu"
									anchorEl={deviceMenuAnchorEl}
									keepMounted
									open={Boolean(deviceMenuAnchorEl)}
									onClose={handleDeviceMenuClose}>
									{devices.map((device) => (
										<MenuItem key={device.id} onClick={() => handleDeviceSelect(device.id)}>
											{device.name}
										</MenuItem>
									))}
								</Menu>
							</>
							<>
								<MDButton
									aria-controls="date-type-menu"
									aria-haspopup="true"
									color="primary"
									onClick={handleMenuClick}
									sx={{ marginRight: "1rem" }}
									variant="contained">
									{dateType === "daily"
										? "Daily"
										: dateType === "weekly"
										? "Weekly"
										: dateType === "monthly"
										? "Monthly"
										: "Yearly"}
								</MDButton>

								<Menu
									id="date-type-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}>
									<MenuItem onClick={() => handleMenuSelect("daily")}>Daily</MenuItem>
									<MenuItem onClick={() => handleMenuSelect("weekly")}>Weekly</MenuItem>
									<MenuItem onClick={() => handleMenuSelect("monthly")}>Monthly</MenuItem>
									<MenuItem onClick={() => handleMenuSelect("yearly")}>Yearly</MenuItem>
								</Menu>
							</>
							<>
								<TextField
									label="Start Date"
									type="date"
									value={startDate}
									onChange={(e) => setStartDate(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ marginRight: "1rem" }}
								/>
								<TextField
									label="End Date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</>
						</Grid>

						<Grid item alignItems="center" justifyContent="center">
							<MDButton variant="contained" color="secondary" onClick={resetFilters}>
								<Restore />
							</MDButton>

							<ToggleButtonGroup
								value={chartType}
								exclusive
								color="primary"
								onChange={handleChartTypeChange}
								style={{ marginLeft: "1rem" }}>
								<ToggleButton value="bar">
									<BarChartIcon />
								</ToggleButton>
								<ToggleButton value="line">
									<ShowChartIcon />
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>
				</MDBox>
				<Footer />
			</DashboardLayout>
		);
	}

	return (
		<DashboardLayout>
			<MDBox py={3}>
				<Grid container spacing={3} alignItems="center" justifyContent="space-between">
					{/* Same controls for date type and device selection */}
					<Grid item alignItems="center" justifyContent="center">
						{/* Device Filter */}
						<>
							<MDButton
								aria-controls="device-menu"
								aria-haspopup="true"
								color="primary"
								onClick={handleDeviceMenuClick}
								sx={{ marginRight: "1rem" }}
								variant="contained">
								{selectedDevice ? `Device: ${selectedDevice}` : "Select Device"}
							</MDButton>

							<Menu
								id="device-menu"
								anchorEl={deviceMenuAnchorEl}
								keepMounted
								open={Boolean(deviceMenuAnchorEl)}
								onClose={handleDeviceMenuClose}>
								{devices.map((device) => (
									<MenuItem key={device.id} onClick={() => handleDeviceSelect(device.id)}>
										{device.name}
									</MenuItem>
								))}
							</Menu>
						</>
						<>
							<MDButton
								aria-controls="date-type-menu"
								aria-haspopup="true"
								color="primary"
								onClick={handleMenuClick}
								sx={{ marginRight: "1rem" }}
								variant="contained">
								{dateType === "daily"
									? "Daily"
									: dateType === "weekly"
									? "Weekly"
									: dateType === "monthly"
									? "Monthly"
									: "Yearly"}
							</MDButton>

							<Menu
								id="date-type-menu"
								anchorEl={anchorEl}
								keepMounted
								color="primary"
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}>
								<MenuItem onClick={() => handleMenuSelect("daily")}>Daily</MenuItem>
								<MenuItem onClick={() => handleMenuSelect("weekly")}>Weekly</MenuItem>
								<MenuItem onClick={() => handleMenuSelect("monthly")}>Monthly</MenuItem>
								<MenuItem onClick={() => handleMenuSelect("yearly")}>Yearly</MenuItem>
							</Menu>
						</>
						<>
							<TextField
								label="Start Date"
								type="date"
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
								style={{ marginRight: "1rem" }}
							/>
							<TextField
								label="End Date"
								type="date"
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</>
					</Grid>

					<Grid item alignItems="center" justifyContent="center">
						<MDButton variant="contained" color="secondary" onClick={resetFilters}>
							<Restore />
						</MDButton>

						<ToggleButtonGroup
							value={chartType}
							exclusive
							color="primary"
							onChange={handleChartTypeChange}
							style={{ marginLeft: "1rem" }}>
							<ToggleButton value="bar">
								<BarChartIcon />
							</ToggleButton>
							<ToggleButton value="line">
								<ShowChartIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
				</Grid>

				<MDBox mt={4.5}>
					{overviewData.devices.map((device, index) => (
						<MDBox key={index} mb={3} mt={3}>
							<MDTypography sx={{ fontSize: "24px" }} mb={2}>
								{device.name}
							</MDTypography>
							<Grid container spacing={3}>
								{device.sensors.length > 0 ? (
									device.sensors.map((sensor, sensorIndex) => (
										<Grid item xs={12} md={6} lg={6} key={sensorIndex}>
											<MDBox mb={3} mt={3}>
												{renderSensorChart(sensor)}
											</MDBox>
										</Grid>
									))
								) : (
									<MDBox mb={3} mt={3} px={3} display="flex" justifyContent="center">
										No data available
									</MDBox>
								)}
							</Grid>
						</MDBox>
					))}
				</MDBox>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default Dashboard;
