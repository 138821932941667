import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import SensorsLiveDataTable from "./data/sensorsLiveDataTable";

function SensorsLive() {
	return (
		<DashboardLayout>
			<SensorsLiveDataTable></SensorsLiveDataTable>
			<Footer />
		</DashboardLayout>
	);
}

export default SensorsLive;
