import React, { useEffect, useState, useMemo } from "react";
import io from "socket.io-client";
import MDTypography from "components/MDTypography";
import DataTable from "customComponents/Tables/DataTable";

const liveSocket = io(
	process.env.REACT_APP_ENV === "development" ? "http://localhost:8089" : "https://dashboard.eleon-energy.com"
);

export default function SensorsLiveDataTable() {
	const [liveData, setLiveData] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const pageSize = 25;

	useEffect(() => {
		liveSocket.on("connect", () => {
			console.log("Connected to Live Sensors Socket");
		});

		liveSocket.on("sensors-data", (sData) => {
			setLiveData((prevData) => {
				const updatedData = [...sData.data, ...prevData];
				setTotalPages(Math.ceil(updatedData.length / pageSize));
				return updatedData;
			});
		});

		liveSocket.on("disconnect", () => {
			console.log("Disconnected from Live Sensors Socket");
		});

		return () => {
			liveSocket.off("sensors-data");
			liveSocket.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handlePageChange = (currentPage) => {
		setPage(currentPage);
	};

	const rows = useMemo(() => {
		return liveData.slice((page - 1) * pageSize, page * pageSize).map((item) => ({
			timestamp: (
				<MDTypography variant="caption" color="text">
					{new Date(item.timestamp).toLocaleString()}
				</MDTypography>
			),
			deviceId: (
				<MDTypography variant="caption" color="text">
					{item.deviceId}
				</MDTypography>
			),
			temperature: (
				<MDTypography variant="caption" color="text">
					{item.temperature ?? "N/A"}
				</MDTypography>
			),
			heat: (
				<MDTypography variant="caption" color="text">
					{item.heat ?? "N/A"}
				</MDTypography>
			),
			humidity: (
				<MDTypography variant="caption" color="text">
					{item.humidity ?? "N/A"}
				</MDTypography>
			),
			tilt: (
				<MDTypography variant="caption" color="text">
					{item.tilt ?? "N/A"}
				</MDTypography>
			),
			rpm: (
				<MDTypography variant="caption" color="text">
					{item.rpm ?? "N/A"}
				</MDTypography>
			),
			power: (
				<MDTypography variant="caption" color="text">
					{item.power ?? "N/A"}
				</MDTypography>
			),
		}));
	}, [liveData, page]);

	const columns = useMemo(
		() => [
			{ Header: "Date", accessor: "timestamp", align: "center" },
			{ Header: "Device", accessor: "deviceId", align: "left" },
			{ Header: "Temperature", accessor: "temperature", align: "left" },
			{ Header: "Heat", accessor: "heat", align: "center" },
			{ Header: "Humidity", accessor: "humidity", align: "center" },
			{ Header: "Tilt", accessor: "tilt", align: "center" },
			{ Header: "RPM", accessor: "rpm", align: "center" },
			{ Header: "Power", accessor: "power", align: "center" },
		],
		[]
	);

	return (
		<>
			<DataTable
				table={{
					columns,
					rows,
				}}
				isSorted={true}
				entriesPerPage={{ defaultValue: pageSize }}
				showTotalEntries={false}
				pagination={{
					size: "large",
					variant: "contained",
					active: true,
					onPageChange: handlePageChange,
					totalPages: totalPages,
					currentPage: page,
				}}
			/>
		</>
	);
}
