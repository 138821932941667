import React, { useEffect, useMemo, useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";
import { getDevices } from "service/service";
import MDTypography from "components/MDTypography";
import DataTable from "customComponents/Tables/DataTable";
import MDButton from "components/MDButton";
import { Icon, IconButton, Tooltip } from "@mui/material";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import DeviceForm from "./deviceForm";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import { unregisterDevice } from "service/service";

function Devices() {
	const [open, setOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [deviceId, setDeviceId] = useState("");
	const [isFormChanged, setIsFormChanged] = useState(false);
	const [devices, setDevices] = useState([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const pageSize = 100;

	const fetchDevices = async () => {
		try {
			const response = await getDevices(page, pageSize);
			setTotalPages(response.totalPages);
			setDevices(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchDevices();
	}, []);

	const handlePageChange = (currentPage) => {
		setPage(currentPage);
	};

	const handleConfirmationOpen = (id) => {
		setDeviceId(id);
		setConfirmationOpen(true);
	};

	const handleUnregisterDevice = async (deviceId) => {
		try {
			console.log("deviceId", deviceId);
			const response = await unregisterDevice(deviceId);
			if (response.message === "OK") {
				const updatedDevices = devices.filter((device) => device._id !== deviceId);
				setDevices(updatedDevices);
			}
			setConfirmationOpen(false);
		} catch (error) {
			console.error("Error unregistering device:", error);
		}
	};

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};

	const columns = useMemo(
		() => [
			{ Header: "Registered", accessor: "date", align: "center" },
			{ Header: "Id", accessor: "id", align: "center" },
			{ Header: "Name", accessor: "name", align: "left" },
			{ Header: "Token", accessor: "token", align: "left" },
			{ Header: "Status", accessor: "status", align: "center" },
			{ Header: "Actions", accessor: "actions", align: "center" },
		],
		[]
	);

	const rows =
		devices?.length > 0
			? devices.map((device) => {
					const token = device.token ?? "N/A";
					const displayToken = token !== "N/A" ? `${token.slice(0, 4)}...${token.slice(-4)}` : "N/A";
					return {
						date: (
							<MDTypography variant="caption" color="text">
								{new Date(device.timestamp).toLocaleString()}
							</MDTypography>
						),
						id: (
							<MDTypography variant="caption" color="text">
								{device.id}
							</MDTypography>
						),
						name: (
							<MDTypography variant="caption" color="text">
								{device.name ?? "N/A"}
							</MDTypography>
						),
						token: (
							<MDTypography variant="caption" color="text">
								{displayToken}
								{token !== "N/A" && (
									<Tooltip title="Copy to clipboard">
										<IconButton size="small" onClick={() => copyToClipboard(device.token)}>
											<ContentCopyIcon fontSize="small" />
										</IconButton>
									</Tooltip>
								)}
							</MDTypography>
						),
						status: (
							<>
								{device.status === "active" ? (
									<span
										style={{
											height: "10px",
											width: "10px",
											backgroundColor: "green",
											borderRadius: "50%",
											display: "inline-block",
										}}></span>
								) : (
									<span
										style={{
											height: "10px",
											width: "10px",
											backgroundColor: "red",
											borderRadius: "50%",
											display: "inline-block",
										}}></span>
								)}
							</>
						),
						actions: (
							<MDButton
								variant="outlined"
								color="primary"
								onClick={() => handleConfirmationOpen(device._id)}>
								<Icon fontSize="medium" sx={{ marginRight: "0.25rem" }}>
									remove_circle
								</Icon>
								UNREGISTER
							</MDButton>
						),
					};
			  })
			: [];

	return (
		<DashboardLayout>
			<MDBox
				display="flex"
				bgColor="white"
				alignItems="center"
				justifyContent="end"
				borderRadius="10px"
				p={2}
				mb={2}>
				<MDBox>
					<MDButton variant="contained" color="primary" onClick={handleClickOpen}>
						<Icon fontSize="medium" sx={{ marginRight: "0.25rem" }}>
							add
						</Icon>
						REGISTER DEVICE
					</MDButton>
				</MDBox>
			</MDBox>
			<DataTable
				table={{
					columns,
					rows,
				}}
				isSorted={false}
				entriesPerPage={{ defaultValue: pageSize, display: false }}
				showTotalEntries={false}
				pagination={{
					size: "large",
					variant: "contained",
					active: true,
					onPageChange: handlePageChange,
					totalPages: totalPages,
					currentPage: page,
				}}
			/>
			<Footer />
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<DeviceForm
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
						devices={devices}
						setDevices={setDevices}
						setOpen={setOpen}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				minWidth={"200px"}
				component={
					<ConfirmationComponent
						message={"Are you sure you want to unregister this device? This action cannot be undone."}
						handleConfirm={() => handleUnregisterDevice(deviceId)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
		</DashboardLayout>
	);
}

export default Devices;
