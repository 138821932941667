import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import Footer from "customComponents/Footer";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import SensorsHistoryDataTable from "./data/sensorsHistoryDataTable";

function SensorsHistory() {
	return (
		<DashboardLayout>
			<SensorsHistoryDataTable></SensorsHistoryDataTable>
			<Footer />
		</DashboardLayout>
	);
}

export default SensorsHistory;
