import Dashboard from "layouts/dashboard";
import Icon from "@mui/material/Icon";
import SensorsHistory from "layouts/sensors-history";
import SensorsLive from "layouts/sensors-live";
import Settings from "layouts/settings";
import Devices from "layouts/devices";
import SignIn from "layouts/authentication/sign-in";
import { signOut } from "service/authentication";

const routes = [
	{
		type: "collapse",
		name: "Overview",
		key: "dashboard",
		icon: <Icon fontSize="small">show_chart</Icon>,
		route: "/dashboard",
		component: <Dashboard />,
	},
	{
		type: "collapse",
		name: "Devices",
		key: "devices",
		icon: <Icon fontSize="small">phoneline</Icon>,
		route: "/devices",
		component: <Devices />,
	},
	{
		type: "collapse",
		name: "Live Data",
		key: "sensors-live",
		icon: <Icon fontSize="small">sensors</Icon>,
		route: "/sensors-live",
		component: <SensorsLive />,
	},
	{
		type: "collapse",
		name: "History",
		key: "sensors-history",
		icon: <Icon fontSize="small">history</Icon>,
		route: "/sensors-history",
		component: <SensorsHistory />,
	},
	{
		type: "collapse",
		position: "top",
		name: "Settings",
		key: "settings",
		icon: <Icon fontSize="small">settings</Icon>,
		route: "/settings",
		component: <Settings />,
	},
	{
		type: "collapse",
		position: "bottom",
		name: "Sign Out",
		key: "sign-out",
		icon: <Icon fontSize="small">logout</Icon>,
		route: "/connect",
		component: <SignIn />,
	},
];

export default routes;
