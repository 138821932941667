import api from "../utils/axios";

export async function getOverview(filterType, startDate, endDate, deviceId) {
	try {
		const response = await api.get("/sensors/overview", {
			params: { startDate, endDate, filterType, deviceId },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching overview data:", error);
		return null;
	}
}

export async function getSensorsData(deviceId, page, pageSize, startDate, endDate) {
	try {
		const response = await api.get("/sensors", {
			params: {
				deviceId: deviceId || undefined,
				page,
				limit: pageSize,
				startDate: startDate || undefined,
				endData: endDate || undefined,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching sensors data:", error);
		return null;
	}
}

export async function getDevices(page, pageSize) {
	try {
		const response = await api.get("/devices", {
			params: {
				page,
				limit: pageSize,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching devices:", error);
		return null;
	}
}

export async function registerDevice(device) {
	try {
		const response = await api.post("/devices", device);
		return response.data;
	} catch (error) {
		console.error("Error registering device:", error);
	}
}

export async function unregisterDevice(deviceId) {
	try {
		const response = await api.delete(`/devices/${deviceId}`);
		return response.data;
	} catch (error) {
		console.error("Error unregistering device:", error);
	}
}
